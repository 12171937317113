@tailwind base;
@tailwind components;
@tailwind utilities;

@import "~react-image-gallery/styles/css/image-gallery.css";

ul.share-buttons{
    list-style: none;
    padding: 0;
  }
  
  ul.share-buttons li{
    display: inline;
  }
  
  ul.share-buttons .sr-only{
    position: absolute;
    clip: rect(1px 1px 1px 1px);
    clip: rect(1px, 1px, 1px, 1px);
    padding: 0;
    border: 0;
    height: 1px;
    width: 1px;
    overflow: hidden;
  }
